jQuery(document).ready(function($) {
    // Initially hide the cross icon and the mobile menu
    $('.cross').hide();
    $('.nav-mobile-menu').hide();

    // Toggle when the menu-bar is clicked
    $('.menu-bar').click(function() {
        $('.nav-mobile-menu').stop().slideDown('slow'); // Ensure the slide down happens smoothly
        $('.menu-bar').hide(); // Hide the burger icon
        $('.cross').show(); // Show the cross icon
    });

    // Toggle when the cross is clicked
    $('.cross').click(function() {
        $('.nav-mobile-menu').stop().slideUp('slow'); // Ensure the slide up happens smoothly
        $('.cross').hide(); // Hide the cross icon
        $('.menu-bar').show(); // Show the burger icon
    });
});